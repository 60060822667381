<template>
  <div id="safeguarding">
    <noOpen :list="list"></noOpen>
    <copyrightprotect></copyrightprotect>
  </div>
</template>

<script>
import noOpen from '@/components/copyrightprotect/noOpen' //顶部开通
import copyrightprotect from '@/components/copyrightprotect/index' //轮播图
export default {
  name: 'safeguarding',
  data() {
    return {
      list: {
        status: 2,
        src: require('@/assets/img/1.3.8/icon_zzxt.png'),
        title: '叮叮开课独家专利盗版追溯系统',
        describe:
          '叮叮开课结合智能算法与自动识别技术，对文件赋予唯一的嵌合式数字加密标签，一旦文件被非法获取及使用，即可通过专利技术对该文件源头进行追踪，精确锁定泄露账户和相关人员信息，协助追踪盗版盗卖，持续保障数据安全。',
        button_name: '申请追踪',
        money: '',
        strike: '',
        function_tu: require('@/assets/img/1.3.8/img_fdzzjst.jpg'),
        tip: ''
      }
    }
  },
  components: {
    copyrightprotect,
    noOpen
  }
}
</script>
